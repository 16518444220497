<script setup lang="ts">
import Sidebar from '@/features/navigation/Sidebar.vue';
import DashboardList from '@/features/dashboardList/DashboardList.vue';
import Header from '@/features/header/Header.vue';

defineProps({
  title: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false,
  },
  headerTitle: {
    type: String,
    default: ''
  },
  mobileOnly: {
    type: Boolean,
    default: false
  }
});

const route = useRoute();
const pathname = route.path;

const session = useSession();
const language = session.get.value.language;

</script>

<template>
  <div class="bg-black">
    <NuxtLayout
      v-if="session && language"
      name="main"
      :title="error ? 'Something went wrong' : title"
    >
        <div
          class="
        flex
        h-screen
        dark:from-gray-900
        from-gray-200
        dark:bg-black
        "
        >
          <Sidebar
            :pathname="pathname"
            :session="session"
          />
          <DashboardList />
          <div class="h-full w-full flex flex-col">
            <Header
              :title="headerTitle ?? title"
              :mobile-only="mobileOnly ?? false"
            />
            <div class="flex flex-1 !font-roboto min-h-0">
              <main class="wrapper h-full w-full font-roboto">
                <div
                  v-if="error"
                  class="text-white flex items-center w-full h-full text-center"
                >
                  <h1 class="text-center w-full text-4xl">
                    Oops! Something went wrong!
                  </h1>
                </div>
                <slot v-else />
              </main>
            </div>
          </div>
        </div>
    </NuxtLayout>
  </div>
</template>
