<script setup lang="ts">
import { ModalTextInput } from '@wisionmonorepo/ui';
import type { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { withUseQuery } from '@wisionmonorepo/api';

const { t } = useI18n();
const apiClient = useApi();
const queryClient = useQueryClient();

const props = defineProps({
  dashboardId: {
    type: String,
    default: null
  },
  onClose: {
    type: Function as PropType<() => void>,
    required: true
  }
});

const updateNameMutation = useMutation(
  ({ name, id } : { name: string, id: string }) =>  withUseQuery(() => apiClient.dashboard.updateName(name, id)),
  {
    onSuccess: () => queryClient.invalidateQueries([apiClient.dashboard.cacheKeys.dashboards]),
  }
);

const handleUpdateName = (name: string) => {
  if (!props.dashboardId) return;

  updateNameMutation.mutate({ name, id: props.dashboardId });
};

</script>

<template>
  <ModalTextInput
    :title-text="t('dashboard.renamedashboard')"
    :max-length="40"
    :confirm-text="t('dashboard.renamedashboard')"
    :show-modal="!!props.dashboardId"
    @close="props.onClose"
    @confirm="handleUpdateName"
  />
</template>
