<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import ModalReleaseNotes from './ModalReleaseNotes.vue';
import ModalSettings from '../settings/ModalSettings.vue';
import { Avatar, SkeletonCircle } from '@wisionmonorepo/ui';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@tanstack/vue-query';
import {
  withUseQuery,
  type UserSession
} from '@wisionmonorepo/api';
import {
  ProfileIconBordered,
  ExitIcon,
  SettingsIcon,
  ReleaseNotesIcon,
} from '@wisionmonorepo/ui';
import ContextSwitcher from './ContextSwitcher.vue';

const { t } = useI18n();
const apiClient = useApi();
const isMounted = ref(false);
const { data: userProfile, isLoading } = useQuery({
  queryKey: [apiClient.user.queryKeys.userProfile],
  queryFn: () => withUseQuery(() => apiClient.user.getUserProfile()),
  enabled: isMounted,
});

const sessionCookie = useCookie('session');
const session = useSession();

const userMenuElement = ref();
const userMenuOpen = ref(false);
const settingsMenuOpen = ref(false);
const releaseNotesModalOpen = ref(false);
const name = computed(() =>
  (userProfile.value?.fullname ?? '')?.length > 0
    ? userProfile.value?.fullname
    : userProfile.value?.username
);

const currentOrg = computed(() =>
  session.get.value?.organizationAccess?.find(
    (org: UserSession['organizationAccess'][number]) => org.id === session.get.value?.currentOrganization
  )
);

onMounted(() => {
  isMounted.value = true;
});

const handleLogOut = async () => {
  await apiClient.auth.logout();
  sessionCookie.value = null;
  location.reload();
};
</script>

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="!isLoading && isMounted"
      ref="userMenuElement"
    >
      <div
        class="relative cursor-pointer"
        @click="userMenuOpen = !userMenuOpen"
      >
        <transition
          :appear="true"
          name="height"
        >
          <div class="flex flex-col items-center relative h-[43px]">
            <div
              class="bg-gray-700 pb-3 absolute top-[15px] left-[50%] translate-x-[-50%] w-[34px] h-full"
            />
            <div class="relative flex gap-2 items-center">
              <div class="w-[35px] h-[35px] text-[10px]">
                <Avatar :name="currentOrg?.name" />
              </div>
            </div>
          </div>
        </transition>

        <div class="flex flex-col items-center space-y-4 select-none">
          <div class="w-[44px] h-[44px] z-5">
            <Avatar
              :avatar-id="userProfile?.avatar"
              :name="name"
            />
          </div>
        </div>
        <teleport to="body">
          <transition
            enter-active-class="transition-all ease-out origin-bottom-left transform"
            leave-active-class="transition-all ease-in origin-bottom-left transform"
            enter-from-class="scale-75 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-to-class="scale-75 opacity-0"
          >
            <div
              v-if="userMenuOpen"
              class="absolute bottom-[25px] z-40 flex items-end left-16 text-white"
            >
              <div class="w-3 mb-3">
                <div
                  class="h-3 origin-bottom-right transform rotate-45 bg-dark-widget"
                />
              </div>

              <div
                class="w-64 space-y-3 rounded-lg bg-dark-widget shadow-full h-80"
              >
                <ContextSwitcher class="pl-3 pt-3" />
                <div class="w-full h-[1px] bg-black" />
                <div>
                  <ul class="text-xs">
                    <li
                      class="rounded cursor-pointer hover:bg-gray-600"
                      @click="settingsMenuOpen = true"
                    >
                      <div class="flex items-center p-3 text-white">
                        <SettingsIcon />
                        <span class="ml-2">Settings</span>
                      </div>
                    </li>
                    <li class="rounded cursor-pointer hover:bg-gray-600">
                      <a
                        :href="'/profile'"
                        @click="userMenuOpen = false"
                      >
                        <div class="flex items-center p-3 text-white">
                          <ProfileIconBordered />
                          <span class="ml-2">{{ t('nav.profile') }}</span>
                        </div>
                      </a>
                    </li>
                    <li
                      class="rounded cursor-pointer hover:bg-gray-600"
                      @click="releaseNotesModalOpen = true; userMenuOpen = false;"
                    >
                      <div class="flex items-center p-3 text-white">
                        <ReleaseNotesIcon />
                        <span class="ml-2">Release notes</span>
                      </div>
                    </li>
                    <li
                      class="rounded cursor-pointer hover:bg-gray-600"
                      @click="handleLogOut"
                    >
                      <div class="flex items-center p-3 text-white">
                        <ExitIcon />
                        <span class="ml-2">{{ t('nav.logout') }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </transition>

          <!-- Overlay to hide the menu -->
          <div
            v-if="userMenuOpen"
            class="absolute top-0 bottom-0 left-0 right-0 bg-transparent z-30"
            @click="userMenuOpen = false"
          />
        </teleport>
      </div>

      <ModalSettings
        v-if="settingsMenuOpen"
        @close="settingsMenuOpen = false"
      />
      <ModalReleaseNotes
        v-if="releaseNotesModalOpen"
        @close="releaseNotesModalOpen = false"
      />
    </div>
    <div
      v-else
      class="w-full flex items-center justify-center"
    >
      <SkeletonCircle class="h-10 w-10" />
    </div>
  </transition>
</template>

<style scoped>
.height-enter-active {
  animation: adjustHeight 0.8s;
}

@keyframes adjustHeight {
  0% {
    height: 0px;
  }

  100% {
    height: 43px;
  }
}
</style>
