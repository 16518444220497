<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { withUseQuery } from '@wisionmonorepo/api';
import { useMutation } from '@tanstack/vue-query';

const { locale, availableLocales } = useI18n();
const apiClient = useApi();

const updateLanguageMutation = useMutation({
  mutationFn: (language: string) => withUseQuery(() => apiClient.user.updateLanguage(language)),
  onSuccess: () => {
    location.reload();
  }
});

</script>

<template>
  <div class="overflow-auto text-white max-h-80">
    <ul class="w-full">
      <li
        v-for="language in availableLocales"
        :key="language"
        class="p-3 cursor-pointer hover:bg-gray-700"
        :class="{ 'bg-blue-500 hover:bg-blue-400': language === locale }"
        @click="updateLanguageMutation.mutate(language)"
      >
        {{ $t(`language.${language}`) }}
      </li>
    </ul>
  </div>
</template>
