<script setup lang="ts">
import { onMounted, ref, type PropType } from 'vue';
import { checkIsMobile } from '../../utils';
import UserMenu from '@/features/profile/UserMenu.vue';
import { isSideBarOpen, isMobile } from './store';
import { dashboardListOpen } from '@/features/dashboardList/store';
import { useStore } from '@nanostores/vue';
import { onSwupEvent } from '../../utils';
import {
  DashboardIcon,
  TaskManagerIcon,
  AlarmIcon,
  DeviceManagementIcon,
} from '@wisionmonorepo/ui';
import { type UserSession } from '@wisionmonorepo/api';

const props = defineProps({
  pathname: {
    type: String,
    required: true,
  },
  session: {
    type: Object as PropType<UserSession>,
    required: true,
  },
});

const $showSidebar = useStore(isSideBarOpen);
const $isMobile = useStore(isMobile);

const pathname = ref(props.pathname);
const $dashboardListOpen = useStore(dashboardListOpen);

const isMounted = ref(false);

const handleOnClickDashboard = (e: MouseEvent) => {
  e.stopPropagation();
  // Uncomment this to reactivate dashboard sidebar
  isSideBarOpen.set(true);
  dashboardListOpen.set(!$dashboardListOpen.value);
};

onMounted(() => {
  isMounted.value = true;
  isMobile.set(checkIsMobile());
  window?.addEventListener('resize', () => {
    isMobile.set(checkIsMobile());
  });
  onSwupEvent(() => {
    dashboardListOpen.set(false);
    pathname.value = window.location.pathname;
    isSideBarOpen.set(false);
  });
});

const isActivePage = (page: string) =>
  pathname.value.toLocaleLowerCase().includes(page);
</script>

<template>
  <nav class="flex bg-black">
    <div
      v-show="$showSidebar && $isMobile"
      id="sidebar-backdrop"
      class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-gray-900 opacity-80"
      @click="isSideBarOpen.set(false)"
    />
    <transition
      enter-active-class="transition-all ease-in-out transform"
      leave-active-class="transition-all ease-in transform"
      enter-from-class="-translate-x-16"
      enter-to-class="translate-x-0"
      leave-to-class="-translate-x-16"
    >
      <div
        v-if="$showSidebar || !$isMobile"
        id="sidebar-content"
        class="absolute z-30 flex flex-col justify-between w-16 h-full py-6.5 text-white bg-dark-widget md:relative md:inline-flex"
      >
        <div class="flex flex-col items-center space-y-4">
          <!-- Wision logo -->
          <div>
            <svg
              class="fill-current"
              width="27"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.426 7.32H7.899l5.268 9.407 5.259-9.407M6.38 2.838c5.547-.027 11.092.029 16.637-.046 1.17-.017 1.556.544 1.9 1.637H6.468c.152 1.014.649 1.263 1.486 1.258 5.584-.029 11.17-.017 16.754-.012 1.537 0 2.123 1.05 1.36 2.416-2.748 4.92-5.525 9.823-8.22 14.772-.57 1.047-1.22 1.316-2.334.941 3.065-5.49 6.114-10.945 9.177-16.431-.935-.387-1.37-.027-1.78.714-2.724 4.934-5.513 9.827-8.22 14.771-.536.982-1.134 1.348-2.303.915 3.056-5.474 6.095-10.924 9.147-16.393-.975-.413-1.392.022-1.79.74-2.712 4.894-5.454 9.77-8.187 14.651-.342.612-.555 1.217-1.505 1.229-.998.015-1.24-.617-1.603-1.27C5.778 17.953 3.141 13.152.4 8.418c-.623-1.074-.51-1.812.296-2.71 3.084 5.506 6.144 10.97 9.224 16.473.764-.59.755-1.132.328-1.887-2.782-4.9-5.493-9.84-8.294-14.728-.57-.996-.588-1.751.335-2.634l9.227 16.47c.74-.653.717-1.207.29-1.962-2.824-4.966-5.6-9.96-8.39-14.948-.754-1.35-.152-2.46 1.357-2.463C10.32.022 15.865.061 21.41 0c1.123-.012 1.634.446 1.903 1.604H4.911c.108 1.055.654 1.239 1.47 1.234"
                fill-rule="evenodd"
              />
            </svg>
          </div>

          <!-- Dynamic dashboard -->
          <div class="flex justify-between w-full">
            <div class="w-2.5" />
            <button
              class="p-2 rounded"
              :class="{ 'bg-gray-600': isActivePage('dashboard') }"
              @click="handleOnClickDashboard"
            >
              <DashboardIcon />
            </button>
            <div class="flex flex-col justify-center w-3 overflow-hidden">
              <div
                v-if="isActivePage('dashboard')"
                class="h-3 origin-bottom-right transform rotate-45 bg-white dark:bg-dark-menu md:dark:bg-black"
              />
            </div>
          </div>

          <!-- Device management -->
          <div class="flex justify-between w-full">
            <div class="w-9px" />
            <NuxtLink to="/devicemanagement">
              <button
                class="p-[6px] rounded"
                :class="{ 'bg-gray-600': isActivePage('devicemanagement') }"
              >
                <DeviceManagementIcon />
              </button>
            </NuxtLink>
            <div class="flex flex-col justify-center w-3 overflow-hidden">
              <div
                v-if="isActivePage('devicemanagement')"
                class="h-3 origin-bottom-right transform rotate-45 bg-white dark:bg-dark-menu md:dark:bg-black"
              />
            </div>
          </div>

          <!-- Task management -->
          <div class="flex justify-between w-full">
            <div class="w-9px" />
            <NuxtLink href="/taskmanagement">
              <button
                class="p-[5px] rounded"
                :class="{ 'bg-gray-600': isActivePage('taskmanagement') }"
              >
                <TaskManagerIcon />
              </button>
            </NuxtLink>

            <div class="flex flex-col justify-center w-3 overflow-hidden">
              <div
                v-if="isActivePage('taskmanagement')"
                class="h-3 origin-bottom-right transform rotate-45 bg-white dark:bg-dark-menu md:dark:bg-black"
              />
            </div>
          </div>

          <!-- Alarm management -->
          <div class="flex justify-between w-full">
            <div class="w-9px" />
            <NuxtLink href="/alarmmanagement">
              <button
                class="p-[5px] rounded"
                :class="{ 'bg-gray-600': isActivePage('alarmmanagement') }"
              >
                <AlarmIcon class="color-white" />
              </button>
            </NuxtLink>

            <div class="flex flex-col justify-center w-3 overflow-hidden">
              <div
                v-if="isActivePage('alarmmanagement')"
                class="h-3 origin-bottom-right transform rotate-45 bg-white dark:bg-dark-menu md:dark:bg-black"
              />
            </div>
          </div>
        </div>

        <!-- Lower buttons -->
        <div class="flex flex-col item-center gap-10">
          <UserMenu />
        </div>
      </div>
    </transition>
  </nav>
</template>
